import Container from 'react-bootstrap/Container';
import { Dropdown } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import { FaChevronDown } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { HiBars3BottomLeft } from "react-icons/hi2";
import { useNavigate, useLocation } from 'react-router-dom';
import LoginModal from "../Login";
import AuthService from "../../services/AuthService";
import { ImExit } from "react-icons/im";
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import CloseSesionModal from "../CloseSesionModal"
import Image from 'react-bootstrap/Image';
import { useCookies } from 'react-cookie';
import { FaRegComment } from "react-icons/fa";

function Header() {

    let navigate = useNavigate();
    let location = useLocation();
    const [cookies] = useCookies(['XSRF-TOKEN']);

    const [currentUser, setCurrentUser] = useState(undefined);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showloginButton, setshowloginButton] = useState(false);
    const handleCloseLoginModal = () => setShowLoginModal(false);
    const handleShowLoginModal = () => setShowLoginModal(true);
    const [endSesionForm, setendSesionForm] = useState(false);
    const [showmm, setShowm] = useState(false);
    const handleClose = () => setShowm(false);

    const handleShow = () => {
        setShowm(true);
    }

    const [show, setShow] = useState(false);

    const handleCloseMenu = () => setShow(false);

    useEffect(() => {

        if (cookies['XSRF-TOKEN'] === undefined) {
            console.log("xsrf")
            AuthService.csrf().then(() => {
            }).catch(error => {
            }).finally(
                window.location.reload()
            )
        }

        const user = AuthService.getCurrentUser();

        if (user) {

            setshowloginButton(false)
            if (location.pathname === '/resetpassword' || location.pathname === '/registrationConfirm') {

                AuthService.logout();
                setshowloginButton(true)
                setCurrentUser(undefined);
                return;

            }

            // надо проверить пользователя на серевре
            AuthService.checkUser().then((response) => {

                if (response.data.user === "ok") {
                    setshowloginButton(true)
                    setCurrentUser(user)

                    // if (username === null) {

                    //     AuthService.getUserProfile().then((responce) => {
                    //         //dispatch(changeUserName(responce.data.name + ' ' + responce.data.surname))
                    //     })

                    // }

                }

                if (response.status === '401') {
                    clouseSession()
                }


            }).catch(error => {
                clouseSession()
            })

        } else {
            setshowloginButton(true)
            setCurrentUser(undefined)
        }

        //setInterval(() => {
        // console.log("1")
        //}, 1000);

        const clouseSession = () => {

            AuthService.logout();
            setshowloginButton(true)
            setCurrentUser(undefined);

            if (location.pathname.includes("profile")) {
                navigate("/")
            }

            setendSesionForm(true)

        }

    }, [location, navigate]);


    const createProfile = () => {
        navigate("/profile")
    }


    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);

        const { pathname } = location;
        const splitLocation = pathname.split("/");
        if (splitLocation[1] === "profile") {
            navigate("/")
        }
    };


    const handleCloseMenuProfile = async () => {

        setShow(false)
        setShowm(false)

        const user = await AuthService.getCurrentUser();

        if (user) {
            navigate("/profile")
        } else {
            setShowLoginModal(true)
        }

    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            {/* &#x25bc; */}
        </a>
    ));
    return (
        <>
            <LoginModal
                show={showLoginModal}
                onHide={handleCloseLoginModal}
            />

            <CloseSesionModal
                show={endSesionForm}
                onHide={() => setendSesionForm(false)}
            />
            <Container className='pt-2'>


                <div className='m1'>

                    <Offcanvas show={showmm} onHide={handleClose} id="menu_mb" className="offcanvas offcanvas-start w-100">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <div class="d-flex align-items-center">
                                    <section class="text-center">
                                        <img src="/logo_m.webp" height={55} width={55} class="img-fluid" alt="logo_m"></img>
                                    </section>

                       
                                        <div className="text-left fw-bold" style={{lineHeight: '2.0ch'}}>
                                            <div className='fs-2'>
                                                15-80</div>

                                            <div>Контакт-Центр</div>
                                    

                                    </div>
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <nav className="pb-4 navbar navbar-expand-*">

                                <ul className="navbar-nav" id="mobile-nav">
                                    <Link to={"/"} onClick={handleClose} className={`main-menu_m nav-link pt-0 ${location.pathname === '/' && 'active'}`}><span className='nowrap'>Головна сторінка</span></Link>
                                    <Link to={"/proportal"} onClick={handleClose} className={`main-menu_m nav-link ${location.pathname === '/proportal' && 'active'}`}><span className='nowrap'>Про портал</span></Link>
                                    <Link to={"/remontworks"} onClick={handleClose} className={`main-menu_m nav-link ${location.pathname === '/remontworks' && 'active'}`}>Ремонтні роботи</Link>
                                    <Link to={"/requests"} onClick={handleClose} className={`main-menu_m nav-link nowrap ${location.pathname === '/requests' && 'active'}`}>Звернення мешканців</Link>
                                    <Link to={"/result"} onClick={handleClose} className={`main-menu_m nav-link nowrap ${location.pathname === '/result' && 'active'}`}>Статистика</Link>
                                    <Link to={"/news"} onClick={handleClose} className={`main-menu_m nav-link nowrap ${location.pathname.includes('/news') && 'active'}`}>Новини</Link>
                                    {/* <Link to={"/offer"} onClick={handleClose} className='main-menu_m nav-link'>Надати пропозиції</Link> */}
                                    <div onClick={handleCloseMenuProfile} className={`main-menu_m nav-link ${location.pathname.includes('/profile') && 'active'}`}>Мій <span className='nowrap'>15-80</span></div>
                                </ul>
                            </nav>
                        
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>



                <div class="m1">
                    <div class="d-flex justify-content-between">
                        <div class="align-self-center">
                            <HiBars3BottomLeft size={45} onClick={handleShow} />

                        </div>
                        <div class="align-self-center">
                            <div class="d-flex align-items-center" onClick={() => { navigate("/") }}>
                                <img src="/logo_m.webp" height={50} width={50} class="img-fluid" alt="logo_m"></img>
                                <div className='me-auto'>
                                    <div className="text-left fw-bold">
                                        <div className='nowrap fs-2'>
                                            15-80</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="align-self-center">
                            {currentUser ? (
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id='usermenu_b'>
                                            <div className='main-button-login2'><AiOutlineUser size={37} /></div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='shadow mb-5 bg-body' id="dropdown-basic3">
                                            <Container>


                                                <Dropdown.Item onClick={createProfile} id="botton-di">


                                                    <div className='exitCabinet'>

                                                        <div class="col d-flex align-items-center pb-0 pt-0">
                                                            <section class="text-center py-1 pe-2">
                                                                <Image height={30} width={30} src='/profile2.png' className='img-fluid shadow-4' alt='...'></Image>
                                                            </section>

                                                            <div className='me-auto text-truncate'>
                                                                Мій 15-80
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Divider />

                                                <Dropdown.Item onClick={logOut} id="botton-di">

                                                    <div className='exitCabinet2'> <ImExit /> Вийти</div>

                                                </Dropdown.Item>

                                            </Container>


                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>

                            ) : (
                                <>
                                    <Button style={{ display: showloginButton ? '' : 'none' }} onClick={handleShowLoginModal} id='usermenu_b' variant="link">
                                        <AiOutlineUserAdd size={37} /></Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>


                <div className='m2'>

                    <div class="d-flex">
                        <div class="align-self-center">
                            <div>
                                <Dropdown show={show} onToggle={(isOpen) => setShow(isOpen)}>
                                    <Dropdown.Toggle as={CustomToggle}>

                                        <span className='main--m'><HiBars3BottomLeft /></span>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id="dropdown-basic2" className='mb-5'>
                                        <button type="button" className='btn-close ps-4' aria-label="Close" onClick={handleCloseMenu}></button>


                                        <Navbar>
                                            <Container className='m2 p-2'>
                                                <Nav className="me-auto" id='main-nav'>
                                                    <Link to={"/proportal"} onClick={handleCloseMenu} className={`main-menu nav-link nowrap ${location.pathname === '/proportal' && 'active'}`} >Про портал</Link>
                                                    <Link to={"/remontworks"} onClick={handleCloseMenu} className={`main-menu nav-link nowrap ${location.pathname === '/remontworks' && 'active'}`}>Ремонтні роботи</Link>
                                                    <Link to={"/requests"} onClick={handleCloseMenu} className={`main-menu nav-link nowrap ${location.pathname === '/requests' && 'active'}`}>Звернення мешканців</Link>
                                                    <Link to={"/result"} onClick={handleCloseMenu} className={`main-menu nav-link nowrap ${location.pathname === '/result' && 'active'}`}>Статистика</Link>
                                                    <Link to={"/news"} onClick={handleCloseMenu} className={`main-menu nav-link nowrap ${location.pathname.includes('/news') && 'active'}`}>Новини</Link>

                                                    {/* <Link to={"/offer"} onClick={handleCloseMenu} className='main-menu nav-link nowrap'>Надати пропозиції</Link> */}

                                                    <div onClick={handleCloseMenuProfile} className={`main-menu nav-link cursorp nowrap pt-4' ${location.pathname.includes('/profile') && 'active'}`}>Мій 15-80</div>
                                                </Nav>
                                                {/* <span onClick={handleCloseMenu} className='menu-close-bt'><AiOutlineCloseCircle /></span> */}

                                            </Container>

                                        </Navbar>


                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="p-1 align-self-center ps-4 main-button-login2" onClick={() => { navigate("/") }}>
                            <div class="col d-flex align-items-center">
                                <section class="text-center">
                                    <img src="/logo_m.webp" height={75} width={75} class="img-fluid" alt="logo_m"></img>
                                </section>

                                <div className='me-auto'>
                                    <div className="text-left fw-bold logo-text">
                                        <div className='nowrap fs-3'>
                                            15-80</div>

                                        <div className='nowrap'>Контакт-Центр м. Запоріжжя</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="ms-auto p-1 align-self-center">
                            {currentUser ? (
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" id='usermenu_b_m2'>
                                            <div className='main-button-login2'>Мій <span className='nowrap'>15-80</span><AiOutlineUser size={34} /></div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='shadow mb-5 bg-body' id="dropdown-basic3">
                                            <Container>


                                                <Dropdown.Item onClick={createProfile} id="botton-di">


                                                    <div className='exitCabinet'>

                                                        <div class="col d-flex align-items-center pb-0 pt-0">
                                                            <section class="text-center py-1 pe-2">
                                                                <Image height={30} width={30} src='/profile2.png' className='img-fluid shadow-4' alt='profile2.png'></Image>
                                                            </section>

                                                            <div className='me-auto text-truncate'>
                                                                Мій 15-80
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Divider />

                                                <Dropdown.Item onClick={logOut} id="botton-di">

                                                    <div className='exitCabinet2'> <ImExit /> Вийти</div>

                                                </Dropdown.Item>

                                            </Container>


                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>

                            ) : (
                                <>
                                    <Button style={{ display: showloginButton ? '' : 'none' }} onClick={handleShowLoginModal} id='usermenu_b_m2' variant="link">Вхід<AiOutlineUserAdd size={34} /></Button>
                                </>
                            )}
                        </div>
                    </div>

                </div>


            </Container >
        </>
    );
}

export default Header;