import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronDown } from "react-icons/fa";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import LoginModal from "../Login";


function Mainpagebaner() {

    const [showLoginModal, setShowLoginModal] = useState(false);
    const handleCloseLoginModal = () => setShowLoginModal(false);
    let navigate = useNavigate();

    const appeal = async () => {

        const user = await AuthService.getCurrentUser();

        if (user) {
            navigate("/profile/createrequest")
        } else {
            setShowLoginModal(true)
        }
    }

    return (
        <>

            <LoginModal
                show={showLoginModal}
                onHide={handleCloseLoginModal}
            />

            <Container>
                <Row className="align-items-center p-logo" id="logomain">

                    <Col>
                        <h2>Вас вітає</h2>
                        <h1>Контакт-Центр</h1>
                        <h1> м. Запоріжжя</h1>
                        <h2>
                            <Button variant="success" onClick={appeal} className='logomain-left-button bt-red shadow-sm'>
                                Подати звернення
                            </Button>
                        </h2>
                    </Col>

                    <Col sm={12} lg={6} className='d-flex justify-content-center'>

                        <Col xs={12} md={8} lg={7} sm={8}>
                            <div className="d-grid gap-4">
                                <Button variant="secondary" className='shadow-sm' onClick={() => { navigate('/remontworks') }}>
                                    Ремонтні роботи
                                </Button>
                                <Button variant="secondary" onClick={() => { navigate('/requests') }} className='nowrap shadow-sm'>
                                    Звернення мешканців
                                </Button>

                                <Dropdown align="center" id='drmainbutton'>
                                    <Dropdown.Toggle variant="secondary" className='bbb shadow-sm'>
                                        Результати роботи<FaChevronDown className='main-button2' />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='shadow-sm rounded'>
                                       <Dropdown.Item className='p-2' onClick={() => { navigate('/result') }}>Статистика</Dropdown.Item>
                                        <Dropdown.Item className='p-2' onClick={() => { navigate('/news') }}>Новини</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>
                        </Col>
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default Mainpagebaner;