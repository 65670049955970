import Container from 'react-bootstrap/Container';
import { IoIosAddCircleOutline } from "react-icons/io";
import React, { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
//import { GrStatusGoodSmall } from "react-icons/gr";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { GoAlert } from "react-icons/go";
import { BsClock } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineDoneAll, MdDone, MdOutlineRemoveDone } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { BiRotateRight } from "react-icons/bi";
import { TbArrowForward } from "react-icons/tb";
import { TbRefresh } from "react-icons/tb";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image'
import ChangePasswordUserProfile from './changePasswordUserProfile';
import UserSettingsModal from './user_settings_modal';
import Telegram_settings from './Telegram_setting_modal'
import MyRepairs from './My_repairs_modal';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios";
import { ThreeDots, ProgressBar } from 'react-loader-spinner'
import { IoMdAlert } from "react-icons/io";
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux'
import { addStatus } from '../redux/action'
import { PatternFormat } from 'react-number-format';
import ModalImage from "react-modal-image";
import { MdOutlineAttachFile } from 'react-icons/md';
import DeleteUser from './deleteUser/deleteUserModal';
import IsApplicantsNotClouse, { isApplicantsNotClouse } from './deleteUser/isApplicantsNotClouse_modal';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { LanimateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



function UserProfile() {

    useEffect(() => {
        document.title = 'Мій 15-80 – Контакт–Центр м. Запоріжжя 1580';
        scroller.scrollTo('start', {
            duration: 1,
            delay: 0,
            smooth: true,
            offset: -90
        })
      }, []);

    const dispatch = useDispatch()

    const [showChangePasswordUserProfile, setshowChangePasswordUserProfile] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [showIsApplicantsNotClouse, setShowIsApplicantsNotClouse] = useState(false);
    const [isApplicantsNotClouse_data, setIsApplicantsNotClouse_data] = useState([]);
    const handleCloseChangePasswordUserProfile = () => setshowChangePasswordUserProfile(false);

    const [showUserSetup, setshowUserSetup] = useState(false);
    const [showTelegram_settings, setShowTelegram_settings] = useState(false);
    const [showMyRepairs, setShowMyRepairs] = useState(false);

    const [requests_by_applicant, setRequests_by_applicant] = useState(null);
    const [showspiner, setshowspiner] = useState(false);
    const [showsblank, setshowsblank] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [userProfile, setUserFrofile] = useState([]);

    const [showDeleteSpiner, setShowDeleteSpiner] = useState(false);
    //pagination
    //
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [blockPagination, setBlockPagination] = useState(false);
    //


    const handlePageChange =(page)=>{
        if (blockPagination){
                return;
                }
                scroller.scrollTo('start', {
                    duration: 300,
                    delay: 10,
                    smooth: true,
                    offset: -90
                })
            setCurrentPage(page)
            

            
    }

    const handleSetup = () => {
        setShow(false);
        setshowUserSetup(true);
    }

    const handleShowDeleteuserModal = () => {
        setShowDeleteSpiner(true)
        if (show) {
            setShow(false)
        }

        axios.post("/api/user/check_applicants_for_deleteUser", {}
        ).then((response) => {
            if (response.status === 200) {
                setShowDeleteSpiner(false)
                if (response.data.length != 0) {
                    setIsApplicantsNotClouse_data(response.data)
                    setShowIsApplicantsNotClouse(true)
                } else {
                    setShowDeleteUser(true);
                }

            } else {
                setShowDeleteSpiner(false)
            }
        }).catch(error => {
            setShowDeleteSpiner(false)
        })


    }

    ////


    const uploadFile = (url, filename, contentType) => {


        fetch('/api/user/filesprivate/' + url, {
            method: 'GET',
            headers: {
                'Content-Type': contentType,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    filename,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });


        // axios.get('/api/files/1')
        //   .then(res => {

        //   })
    }

    ////

    const handleCloseSetupModal = () => {

        AuthService.profile().then((responce) => {
            setUserFrofile(responce.data)
            // if (responce.headers.showalert === 'true') {
            //     setShowAlert(true)
            // } else {
            //     setShowAlert(false)
            // }

            if (responce.headers.showalert === 'true') {

                setShowAlert(true)
                dispatch(addStatus(true))

            } else {
                dispatch(addStatus(false))
                setShowAlert(false)
            }
            //    dispatch(changeUserName(responce.data.name + ' ' + responce.data.surname))
        })

        setshowUserSetup(false);
    }

    const DeleteUserSpiner = () => {
        return (
            <>
                <ProgressBar
                    visible={showDeleteSpiner}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                /></>
        );
    }

    const handleResetPassword = () => {

        setShow(false);
        setshowChangePasswordUserProfile(true);
    }

    const handleMyRepairs = () => {

        setShow(false);
        setShowMyRepairs(true);
    }

    const handleMyRepairsClouse = () => {
        setShowMyRepairs(false);
    }

    let navigate = useNavigate();


 

    useEffect(() => {
       
        setshowspiner(true)
        const user = AuthService.getCurrentUser();

        if (user) {
            AuthService.profile().then((response) => {

                if (response.status === 200) {

                    if (response.headers.showalert === 'true') {

                        setShowAlert(true)
                        dispatch(addStatus(true))

                    } else {
                        dispatch(addStatus(false))
                    }

                    setUserFrofile(response.data)

                } else {
                    setshowspiner(false)

                }

            }).catch(error => {
                setshowspiner(false)

            })

        } else {
            navigate("/");

        }

        // axios.post("/api/user/requests_by_applicant/"+currentPage, {}

        // ).then((response) => {

        //     if (response.data.message === 'fail') {
        //         setshowsblank(true)
        //     } else {
        //         setCurrentPage(response.number)
        //         setTotalPages(response.data.totalPages)
        //         console.log(response.data.totalPages)
        //         setRequests_by_applicant(response.data)

        //     }

        //     setshowspiner(false)

        // }).catch(error => {

        // })




    }, []);

    const handleUrlbot = () => {
        setShow(false);
        setShowTelegram_settings(true)
        //  window.open('https://t.me/colcentrebot?start='+keyToken.token, '_blank');
    }

    const handleUpdate = () => {
        if (blockPagination){
            return;
        }
        setCurrentPage(1)
    }

    useEffect(() => {
       // setRequests_by_applicant(null)
       setBlockPagination(true)

      
      

      //setshowspiner(true)

        const page = currentPage - 1;
        axios.post("/api/user/requests_by_applicant/" + page, {}

        ).then((response) => {
            if (response.data.message === 'fail') {
                setshowsblank(true)
            } else {
               
                setCurrentPage(response.data.number + 1)
                setTotalPages(response.data.totalPages)
                setRequests_by_applicant(response.data)

            }

            setshowspiner(false)
            setBlockPagination(false)

        }).catch(error => {
            setshowspiner(false)
            setBlockPagination(false)
        })


    }, [currentPage]);



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCreateTequest = () => {

        if (!showAlert) {
            navigate("/profile/createrequest");
        }

    }

    return (

        <>

            <UserSettingsModal
                show={showUserSetup}
                onHide={handleCloseSetupModal}
            />
            <Telegram_settings
                show={showTelegram_settings}
                onHide={() => { setShowTelegram_settings(false) }}
            />

            <MyRepairs
                show={showMyRepairs}
                onHide={handleMyRepairsClouse}
            />

            <ChangePasswordUserProfile
                show={showChangePasswordUserProfile}
                onHide={handleCloseChangePasswordUserProfile}
            />

            <DeleteUser
                show={showDeleteUser}
                onHide={() => { setShowDeleteUser(false) }}
            />


            <IsApplicantsNotClouse
                show={showIsApplicantsNotClouse}
                onHide={() => { setShowIsApplicantsNotClouse(false) }}
                data={isApplicantsNotClouse_data}
            />

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Мої дані</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className="card-user-2 pb-1">
                        <Card className='shadow bg-body rounded'>
                            <Card.Body>

                                <section class="text-center pb-1">
                                    <Image height={70} width={70} src='/profile.png' className='img-fluid shadow-4' alt='...'></Image>
                                </section>
                                <section class="text-center py-2"><div className='fw-bold'>{userProfile.surname} {userProfile.name} {userProfile.patronymic_UA}</div></section>
                                <section class="text-center pt-2 border-top">
                                    {userProfile.username}
                                </section>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="card-user-2 py-1">
                        <Card className='shadow bg-body rounded'>
                            <Card.Body>

                                <div class="col d-flex align-items-center" onClick={handleMyRepairs}>
                                    <span className='profile-icon-m'>
                                        <Image height={35} width={35} src='/my_repair.png' className='img-fluid shadow-4' alt='...'></Image>
                                    </span>

                                    <div className='me-auto'>
                                        <span className='profile-icon_rep-m'>Мої ремонтні роботи</span>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>



                    <Col className="card-user-2 py-1">
                        <Card className='shadow bg-body rounded'>
                            <Card.Body>


                                <div class="col d-flex align-items-center border-bottom pb-1" onClick={handleSetup}>
                                    <span className='profile-icon-m'>
                                        <AiOutlineSetting />
                                    </span>

                                    <div className='me-auto'>
                                        <span className='profile-icon_rep-m'
                                        >Налаштування</span>
                                    </div>

                                </div>
                                <div class="col d-flex align-items-center border-bottom pt-1 pb-1" onClick={handleResetPassword}>
                                    <span className='profile-icon-m'>
                                        <RiLockPasswordLine />
                                    </span>

                                    <div className='me-auto'>
                                        <span className='profile-icon_rep-m'>
                                            Змінити пароль
                                        </span>

                                    </div>

                                </div>
                                <div class="col d-flex align-items-center addevent-groupe border-top"
                                    onClick={handleUrlbot}>
                                    <span className='profile-icon_rep_2'>
                                        <FaTelegramPlane />
                                    </span>

                                    <div className='me-auto'>
                                        <span className='profile-icon_rep'>
                                            Telegram Чат-бот
                                        </span>

                                    </div>

                                </div>


                            </Card.Body>
                        </Card>
                    </Col>


                    {userProfile.mobile_PHONE != null
                        &&
                        <>
                            <Col className="card-user-2 py-1">
                                <Card className='shadow bg-body rounded'>
                                    <Card.Body>

                                        <div class="col d-flex align-items-center border-bottom pb-1">
                                            <span className='profile-icon-m'>
                                                <FiPhone />
                                            </span>

                                            <div className='me-auto text-truncate'>
                                                {/* {userProfile.mobile_PHONE} */}
                                                <PatternFormat value={userProfile.mobile_PHONE} format="(###)###-##-##" displayType="text" />
                                            </div>

                                        </div>



                                        <div class="col d-flex align-items-center pt-1">
                                            <span className='profile-icon-m'>
                                                <GoHome />
                                            </span>

                                            <div className='me-auto'>
                                                {userProfile.street} {userProfile.house}{userProfile.corpus !== null && <>, Копус {userProfile.corpus}</>}

                                                {userProfile.flat != null &&
                                                    <>
                                                        , кв. {userProfile.flat}
                                                    </>
                                                }
                                            </div>

                                        </div>

                                    </Card.Body>
                                </Card>

                            </Col>

                        </>
                    }

                    <Row className="justify-content-center">
                        <Col className="text-center p-2 addevent-groupe" onClick={() => { handleShowDeleteuserModal() }}>
                            {!showDeleteSpiner &&
                                <span style={{ fontSize: '14px' }}>Видалити обліковий запис</span>}
                            {DeleteUserSpiner()}
                        </Col>
                    </Row>

                </Offcanvas.Body>
            </Offcanvas>
            <Container id='start' className='pt-2'>

                {/* <div class="d-flex align-items-center p-3 cabinet-header">
                    <h1 class="d-flex align-items-center fs-4 text-white mb-0">
                        Мій 15-80
                    </h1>
                    <div onClick={handleShow} class="ms-auto link-light m1"><GiHamburgerMenu /></div>
                </div> */}

                <Navbar className='cabinet-header'>
                    <Container>
                        <Navbar.Brand><span className='text-white'>Мій 15-80</span></Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <div onClick={handleShow} class="ms-auto link-light m1"><GiHamburgerMenu /></div>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>



                <Row className='py-4'>

                    {!showAlert &&

                        <div class="d-flex justify-content-end">
                            <div className='addevent addevent-groupe' onClick={handleCreateTequest}>
                                <IoIosAddCircleOutline />
                                <span className='addevent-text m2'>Створити звернення</span>
                            </div>
                        </div>}

                    <Col className='m2' lg={4} xl={4} xxl={4}>
                        <h4 class="bd-title">Мої дані</h4>
                        <Col className="card-user-2 pb-2">
                            <Card className='shadow bg-body rounded'>
                                <Card.Body>

                                    <section class="text-center py-1">
                                        <Image height={70} width={70} src='/profile.png' className='img-fluid shadow-4' alt='...'></Image>
                                    </section>
                                    <section class="text-center py-1"><div className='fw-bold'>{userProfile.surname} {userProfile.name} {userProfile.patronymic_UA}</div></section>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="card-user-2 py-2">
                            <Card className='shadow bg-body rounded'>
                                <Card.Body>

                                    <div className={`${userProfile.mobile_PHONE != null ? "col d-flex align-items-center pb-2" : "col d-flex align-items-center"}`}>
                                        <span className='profile-icon'>
                                            <MdOutlineAlternateEmail />
                                        </span>

                                        <div className='me-auto text-truncate'>
                                            {userProfile.username}
                                        </div>

                                    </div>

                                    {userProfile.mobile_PHONE != null
                                        &&
                                        <>

                                            <div className="col d-flex align-items-center py-2 border-bottom border-top">
                                                <span className='profile-icon'>
                                                    <FiPhone />
                                                </span>

                                                <div className='me-auto text-truncate'>
                                                    {/* {userProfile.mobile_PHONE} */}
                                                    <PatternFormat value={userProfile.mobile_PHONE} format="(###)###-##-##" displayType="text" />
                                                </div>
                                            </div>
                                            <div class="col d-flex align-items-center pt-2">
                                                <span className='profile-icon'>
                                                    <GoHome />
                                                </span>

                                                <div className='me-auto'>
                                                    {userProfile.street} {userProfile.house}{userProfile.corpus !== null && <>, Копус {userProfile.corpus}</>}

                                                    {userProfile.flat !== null &&
                                                        <>
                                                            , кв. {userProfile.flat}
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                        </>}

                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="card-user-2 py-2">
                            <Card className='shadow bg-body rounded'>
                                <Card.Body>
                                    <div class="col d-flex align-items-center py-2 addevent-groupe2" onClick={handleMyRepairs}>
                                        <div className='profile-icon rep-img'></div>
                                        <div className='me-auto'>
                                            <span className='profile-icon_rep'>Мої ремонтні роботи</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col className="card-user-2 py-2">
                            <Card className='shadow bg-body rounded'>
                                <Card.Body>


                                    <div class="col d-flex align-items-center border-bottom addevent-groupe pb-1" onClick={handleSetup}>
                                        <span className='profile-icon_rep_2'>
                                            <AiOutlineSetting />
                                        </span>

                                        <div className='me-auto'>
                                            <span className='profile-icon_rep'
                                            >Налаштування</span>
                                        </div>

                                    </div>
                                    <div class="col d-flex align-items-center border-bottom addevent-groupe py-1" onClick={handleResetPassword}>
                                        <span className='profile-icon_rep_2'>
                                            <RiLockPasswordLine />
                                        </span>

                                        <div className='me-auto'>
                                            <span className='profile-icon_rep'>
                                                Змінити пароль
                                            </span>

                                        </div>

                                    </div>
                                    <div class="col d-flex align-items-center addevent-groupe pt-1"
                                        onClick={handleUrlbot}>
                                        <span className='profile-icon_rep_2'>
                                            <FaTelegramPlane />
                                        </span>

                                        <div className='me-auto'>
                                            <span className='profile-icon_rep'>
                                                Telegram Чат-бот
                                            </span>

                                        </div>

                                    </div>


                                </Card.Body>
                            </Card>
                            <Row className="justify-content-center">
                                <Col className="text-center p-2 addevent-groupe" onClick={() => { handleShowDeleteuserModal() }}>
                                    {!showDeleteSpiner &&
                                        <span style={{ fontSize: '14px' }}>Видалити обліковий запис</span>}
                                    {DeleteUserSpiner()}
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <h4 class="bd-title">Мої звернення<span onClick={handleUpdate} className='user-refresh-icon ps-2'><TbRefresh /></span></h4>
                        {/* <Card className='shadow-sm bg-body rounded repeir-card h-100'> */}

                        <Alert variant='warning' show={showAlert}>
                            <GoAlert /> Ви не можете подати звернення через те, що не заповнили повністю свій профіль. &nbsp;
                            <Alert.Link onClick={handleSetup}>Заповнити</Alert.Link>
                        </Alert>

                        {showspiner &&

                            <div className='d-grid d-flex justify-content-center py-5'>
                                <ThreeDots
                                    height="160"
                                    width="160"
                                    radius="10"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        }
                        {showsblank &&

                            <Card className='shadow bg-body rounded'>
                                <Card.Body>

                                    <div class="notfound p-4">

                                        <section class="text-center"><span className='rep2-icon'><IoMdAlert /></span></section>
                                        <section class="text-center pt-2"><span className='fw-normal'>Звернень не знайдено</span></section>

                                    </div>
                                </Card.Body>
                            </Card>
                        }

                        <Accordion alwaysOpen className='shadow bg-body rounded'>
                            {!showspiner && requests_by_applicant && requests_by_applicant.content.map(d => (
                                <Accordion.Item eventKey={d.id}>
                                    <Accordion.Header>
                                        {d.status_id === 0 &&
                                            <Col><span className='statusicon_wait2'><BiRotateRight /></span></Col>
                                        }
                                        {d.status_id === 1 &&
                                            <Col><span className='statusicon_wait'><BsClock /></span></Col>
                                        }
                                        {d.status_id === 2 &&
                                            <Col><span className='statusicon'><MdOutlineDoneAll /></span></Col>
                                        }
                                        {d.status_id === 3 &&
                                            <Col><span className='statusicon'><MdDone /></span></Col>
                                        }
                                        {d.status_id === 4 &&
                                            <Col><span className='statusicon_RemoveDone'><MdOutlineRemoveDone /></span></Col>
                                        }
                                        {d.status_id === 5 &&
                                            <Col><span className='statusicon_no'><MdDone /></span></Col>
                                        } {d.status_id === 6 &&
                                            <Col><span className='statusicon_no'><TbArrowForward /></span></Col>
                                        }
                                        {d.is_emergency != null &&
                                            <span className='repeirs-alarm-user-profile'><IoMdAlert /></span>}

                                        <Container>
                                            <Row>
                                                <Col sm={12} className='py-1'>
                                                    <span className='nowrap'>від {d.date_beg}</span>

                                                    {d.request_number &&
                                                        <>
                                                            <span className='pe-1 ps-2'>№</span><b>{d.request_number}</b></>
                                                    }

                                                </Col>
                                                {/* <Col sm={12} className='py-1'> */}

                                                {/* </Col> */}

                                            </Row>
                                            <Row>
                                                <Col className='fs-5'>{d.requet_status}</Col>
                                                {/* <Col className='m2'><b>Адреса:</b> вул. Магара 6а, кв 811</Col> */}

                                            </Row>
                                            <Row>

                                                {/* <span className='nowrap m1'>вул. Магара 64а, кв 8141</span> */}
                                                <span className='m1 pt-2'>
                                                    <b>Адреса:</b><br />
                                                    {d.street_name},

                                                    <span className='ps-2'>{d.house_num}</span>

                                                    {/* {userProfile.flat != null &&
                                                        <>
                                                            , кв. {userProfile.flat}
                                                        </>
                                            } */}

                                                </span>
                                            </Row>
                                        </Container>
                                        <Container className='m2'>
                                            <Col><b>Адреса:</b><br /><span className=''>
                                                {d.street_name},
                                                <span className='ps-2'>{d.house_num}</span>
                                            </span></Col>
                                        </Container>
                                    </Accordion.Header>
                                    <Accordion.Body className='requestbody'>
                                        <Container className='pb-3'>
                                            <div class="user--app-title d-flex justify-content-center"><Col><b>Проблема: </b></Col></div>

                                            <div class="d-flex justify-content-around">{d.is_emergency != null &&
                                                <div class="col d-flex justify-content-end fs-6"> <span className='repeirs-alarm-user-profile2'><IoMdAlert /> Аварійне звернення!</span></div>

                                            }</div>
                                            <Row className='pb-3'>

                                                <div class="col d-flex align-items-center py-0">
                                                    <div class="userprofile-problem-icon" style={{ backgroundImage: `url( ${'/' + d.problem_id + '.webp'} )` }}></div>
                                                    <div className='me-auto'>
                                                        <div className='fw-bold'>{d.problem}</div>
                                                    </div>
                                                </div>
                                                <Col sm={12}>{d.reason}</Col>
                                            </Row>

                                            {d.description && <>
                                                <div className='pb-2'>
                                                    <div class="user--app-title d-flex justify-content-center pb-1"><Col><b>Опис проблеми: </b></Col></div>
                                                    <Row>  <Col className='pt-1' sm={12}>{d.description}</Col></Row></div>
                                            </>}

                                            {(d.files.length !== 0) && d.files.filter(item => item.field_id === 1).map((fileitem, item) => (
                                                <>

                                                    {(item === 0 && !d.description) && <>
                                                        <div className='pb-2'>
                                                            <div class="user--app-title d-flex justify-content-center pb-1 pt-3"><Col><b>Опис проблеми: </b></Col></div>
                                                        </div>
                                                    </>
                                                    }

                                                </>
                                            ))}
                                            {(d.files.length !== 0) && <>
                                                <div class="d-flex justify-content-start pb-2 pt-3">
                                                    {(d.files.length !== 0) && d.files.map(fileitem => (

                                                        <>
                                                            {fileitem.field_id === 1 &&

                                                                <div class="d-inline p-2" key={fileitem.id}>
                                                                    <ModalImage
                                                                        hideDownload
                                                                        small={'/api/user/filesprivate/small/' + fileitem.id}
                                                                        large={'/api/user/filesprivate/' + fileitem.id}
                                                                        alt="Фото до"
                                                                        className="user--files"
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                            </>}

                                            {d.resp_appl && <>
                                                <div class="user--app-title d-flex justify-content-center pb-1 pt-2"><Col><b>Відповідь: </b></Col></div>
                                                <Row><Col sm={12}>{d.resp_appl}</Col></Row>
                                            </>}



                                            {(d.files.length !== 0) && d.files.filter(item => item.field_id === 2).map((fileitem, item) => (
                                                <>

                                                    {(item === 0 && !d.resp_appl) && <>
                                                        <div class="user--app-title d-flex justify-content-center p1-2 pt-2"><Col><b>Відповідь: </b></Col></div>
                                                    </>
                                                    }

                                                </>
                                            ))}
                                            {(d.files.length !== 0) && <>
                                                <div class="d-flex justify-content-start pb-2 pt-3">
                                                    {d.files && d.files.map((fileitem) => (
                                                        <>
                                                            {(fileitem.field_id === 2) &&
                                                                <>
                                                                    <div class="d-inline p-2" key={fileitem.id}>
                                                                        <ModalImage
                                                                            hideDownload
                                                                            small={'/api/user/filesprivate/small/' + fileitem.id}
                                                                            large={'/api/user/filesprivate/' + fileitem.id}
                                                                            alt="Фото після"
                                                                            className="user--files"
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    ))}

                                                </div>
                                            </>}

                                            {(d.files.length !== 0) && d.files.filter(item => item.field_id === 3).map((fileitem, item) => (
                                                <>

                                                    {(item === 0 && !d.resp_appl) && <>
                                                        <div class="user--app-title d-flex justify-content-center p1-2 pt-2"><Col><b>Файли: </b></Col></div>
                                                    </>
                                                    }

                                                </>
                                            ))}

                                            {(d.files.length !== 0) && <>
                                                <div class="pb-2 pt-3">
                                                    {(d.files.length !== 0) && d.files.filter(item => item.field_id === 3).map((fileitem, item) => (
                                                        <>

                                                            <Row >
                                                                <Col sm={12} className='pt-1 pb-2' style={{ cursor: 'pointer' }}
                                                                    onClick={() => uploadFile(fileitem.id, 'Звернення ' + (d.request_number) + '_' + (item + 1) + '.' + fileitem.file_type, fileitem.mime_type)}><MdOutlineAttachFile />{'Завантажити файл ' + (item + 1)}
                                                                </Col>
                                                            </Row>



                                                        </>
                                                    ))}

                                                </div>
                                            </>}








                                        </Container>

                                        <Container className='user-request_bottom py-2'>
                                            <Row>
                                                <Col className='pt-1 pb-1'><b>Планова дата виконання: </b><span className='nowrap'>{d.date_plan}</span></Col>

                                            </Row>

                                            <Row>

                                                <Col className='pt-1 pb-1'><b>Фактична дата виконання: </b><span className='nowrap'>{d.date_end}</span></Col>
                                            </Row>

                                            <Row>
                                                <Col className='pt-1 pb-1'><b>Виконавець: </b>{d.executor}</Col>

                                            </Row>

                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}

                        </Accordion>

                        {!showspiner && totalPages >1&& <>
                        <Row className='pt-5'>
                        <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </Row>
                    </>}
                    </Col>
                    
                </Row>

               
                    
            </Container >
        </>
    );
}

export default UserProfile;