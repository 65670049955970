import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import React, { useEffect, useState } from 'react';
import { FiCalendar } from "react-icons/fi";
import Page from '../page';
import NewsServive from '../../services/newsService';
import { MdNavigateNext } from "react-icons/md";
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

function NewsComponent() {
    let navigate = useNavigate();
    const { id } = useParams();

    const [newsItem, setNewsItem] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [news, setNews] = useState([]);
    const [showspiner, setShowspiner] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        NewsServive.getnewsitem(id).then((response) => {
            document.title = response.data.title_UA + ' – Контакт–Центр м. Запоріжжя 1580';
            setNewsItem(response.data);
        });

        NewsServive.getnews(itemOffset).then((response) => {
            setNews(response.data.content);
            setShowspiner(false);
        }).catch((error) => {
            console.log(error);
        });

    }, [id, itemOffset]);

    const handleNews = (id) => {
        navigate("/news/newsitem/" + id);
    };

    return (
        <>
            <Container>
                <Page />

                {showspiner &&
                    <div className='d-grid d-flex justify-content-center p-5'>
                        <ThreeDots
                            height="140"
                            width="140"
                            radius="7"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            visible={true}
                        />
                    </div>
                }

                {!showspiner &&
                    <>
                        <div className='pt-3 pb-4 news-next'>
                            <span className='cp news--nav'>
                                <Link className='news-next' to={`/news`}>Новини</Link>
                            </span>
                            <span className='news-next-icon'><MdNavigateNext /></span>
                            {newsItem.title_UA}
                        </div>

                        <Row>
                            <Col sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <h2>{newsItem.title_UA}</h2>
                                <div>
                                    <FiCalendar /> <span className='news-date'>{newsItem.create_DATE}</span>
                                </div>
                                <div className='py-4 news-desc'>{newsItem.description_UA}</div>

                                <div className='bg-body rounded'>
                                    <Card.Body>
                                        {newsItem.header_IMG_ID && (
                                            <Image
                                                className='bd-placeholder-img card-img pb-3 img-wrap'
                                                width="100%"
                                                height="auto"
                                                alt=""
                                                src={`/api/files/${newsItem.header_IMG_ID}`}
                                                onError={(e) => { e.target.onerror = null; e.target.src = '/no-image-icon.png'; }} // Error handling
                                            />
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: newsItem.text_UA }} />
                                    </Card.Body>
                                </div>
                            </Col>

                            <Col sm={12} md={12} lg={4} xl={4} xxl={4}>
                                <div className='newslist-header pt-5 pt-sm-5 pt-md-5 pt-lg-1 pt-xl-1 pt-xxl-1 ps-4'>
                                    Останні новини
                                </div>

                                {news && news.map(d => (
                                    <div
                                        key={d.id}
                                        className="d-flex newslist-item p-2"
                                        onClick={() => handleNews(d.id)}
                                    >
                                        <div className="newslist-icon">
                                            <Image
                                                className='me-4 newslist-icon-img img-wrap'
                                                width="90px"
                                                height="90px"
                                                alt=""
                                                src={`/api/files/${d.header_IMG_ID}`}
                                                onError={(e) => { e.target.onerror = null; e.target.src = '/no-image-icon.png'; }} // Error handling
                                            />
                                        </div>

                                        <div className="news-text-wrap">
                                            <div className="me-auto pt-2 newslist-text">{d.title_UA}</div>
                                            <footer className="mt-2 newlist-footer">{d.create_DATE}</footer>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        </>
    );
}

export default NewsComponent;